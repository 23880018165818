<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn v-if="record.deletable" text small @click.stop="archive">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
            <v-card-text>
              <template v-for="field in usual_fields">
                <base-field
                    :key="field.name"
                    :value="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
            </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("translations"),
        name: "translations",
        model: "translation",
      },
      loading: false,
      record: {
      },
      usual_fields: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(id) {
      this.loading = true;
      this.usual_fields = [];
      this.openModal(this.modal_name);
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.record = response.data;
          this.getFields();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$store.dispatch("refreshTranslations");
                  this.$emit("refresh");
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("successfully_archive"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    reset() {
      this.record = {
      };
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
};
</script>
